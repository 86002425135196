import React from 'react';
import { times } from 'lodash';
import { Table, Header, Divider, Message } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';
import { Image, Label } from 'semantic';
import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';
import { GalleryContext } from 'components/GalleryProvider.js';
import Map from 'components/GoogleMaps/googleMaps';
import ReactPlayer from 'react-player/lazy';

@screen
export default class VenueOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shownImage: { src: null, title: null },
    };
  }

  handleOverlay = (element) => {
    this.context.setImage(
      element.target.src,
      element.target.getAttribute('image-name')
    );
  };

  static contextType = GalleryContext;

  render() {
    const { venue } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />

        <Divider hidden />

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Business Status</Table.Cell>
              <Table.Cell>{venue.placesStatus}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{venue.description}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Highlighted</Table.Cell>
              <Table.Cell>{venue.highlighted ? '✅' : '❌'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Discoverable</Table.Cell>
              <Table.Cell>{venue.discoverable ? '✅' : '❌'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Categories</Table.Cell>
              <Table.Cell>
                {venue.categories.map((category) => (
                  <Label key={category.id} content={category.name} />
                ))}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Start Date (event)</Table.Cell>
              <Table.Cell>
                {venue.eventStartDate
                  ? formatDateTime(venue.eventStartDate)
                  : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>End Date (event)</Table.Cell>
              <Table.Cell>
                {venue.eventEndDate ? formatDateTime(venue.eventEndDate) : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Foursquare Categories</Table.Cell>
              <Table.Cell>
                {venue.foursquare?.categories?.map((category) => (
                  <Label key={category.id} content={category.name} />
                ))}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>{venue.address}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Phone</Table.Cell>
              <Table.Cell>{venue.phone}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Website</Table.Cell>
              <Table.Cell>
                <a href={venue.website}>{venue.website}</a>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Instagram</Table.Cell>
              <Table.Cell>
                {venue.instagram ? (
                  <a href={`https://instagram.com/${venue.instagram}`}>
                    @{venue.instagram}
                  </a>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Rating</Table.Cell>
              <Table.Cell>
                {times(Math.round(venue.rating), () => '⭐️')} (
                {`${venue.rating} from ${venue.totalRatings} ratings`})
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Price</Table.Cell>
              <Table.Cell>
                {times(Math.round(venue.priceLevel), () => '💰')} (
                {venue.priceLevel})
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>isLit</Table.Cell>
              <Table.Cell>{venue.isLit ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Source</Table.Cell>
              <Table.Cell>{venue.source}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Last Sync</Table.Cell>
              <Table.Cell>{formatDateTime(venue.lastSyncedAt)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(venue.createdAt)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(venue.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header as="h3">Map</Header>

        <Map
          coordinates={{
            latitude: venue.location.coordinates[1],
            longitude: venue.location.coordinates[0],
          }}
        />

        <Header as="h3">Custom Media</Header>

        {venue.media.length != 0 ? (
          <Image.Group size="medium">
            {venue.media.map((media) =>
              media.mimeType.includes('image') ? (
                <Image
                  onClick={this.handleOverlay}
                  key={media.id}
                  image-name={media.filename}
                  src={urlForUpload(media)}
                />
              ) : (
                <ReactPlayer
                  controls={true}
                  key={media.id}
                  url={media.cdnUrl || media.rawUrl}
                />
              )
            )}
          </Image.Group>
        ) : (
          <Message>No Media</Message>
        )}
      </React.Fragment>
    );
  }
}
