import React from 'react';
import { Header, Table, Loader } from 'semantic';
import screen from 'helpers/screen';
import { formatDateTime } from 'utils/date';
import Menu from './Menu';

@screen
export default class UserOverview extends React.Component {
  render() {
    const { user, subs } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />

        {!user ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <Header as="h3">Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>First Name</Table.Cell>
                  <Table.Cell>{user.firstName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Last Name</Table.Cell>
                  <Table.Cell>{user.lastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Username</Table.Cell>
                  <Table.Cell>{user.nickname}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Instagram</Table.Cell>
                  <Table.Cell>
                    {user.instagram ? (
                      <a href={`https://instagram.com/${user.instagram}`}>
                        @{user.instagram}
                      </a>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Followers</Table.Cell>
                  <Table.Cell>{user.statistics.followers}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Following</Table.Cell>
                  <Table.Cell>{user.statistics.following}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Posts</Table.Cell>
                  <Table.Cell>{user.statistics.posts}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Subscribed venues</Table.Cell>
                  <Table.Cell>
                    {subs.length
                      ? subs.map(({ venue }) => (
                          <li key={venue.id}>{venue.name}</li>
                        ))
                      : '0'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Phone</Table.Cell>
                  <Table.Cell>{user.phone}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Email</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Verified</Table.Cell>
                  <Table.Cell>{user.verified ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Highlighted</Table.Cell>
                  <Table.Cell>{user.highlighted ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Enabled</Table.Cell>
                  <Table.Cell>{user.disabled ? 'No' : 'Yes'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Test user</Table.Cell>
                  <Table.Cell>{user.isTest ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Created At</Table.Cell>
                  <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Updated At</Table.Cell>
                  <Table.Cell>{formatDateTime(user.updatedAt)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
